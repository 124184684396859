<template>
  <b-card>
    <div class="custom-container">
      <!-- banner bienvenida -->
      <div
          class="row d-flex py-5 mb-5 rounded banner-content position-relative"
      >
        <!-- arrow down scroll -->
        <div>
          <i
              class="fas fa-arrow-down fa-bounce position-absolute"
              style="bottom: -30px; left: 50%; font-size: 2rem"
          ></i>
        </div>
        <!-- icons float -->
        <img
            class="position-absolute d-none d-lg-block"
            style="left: 50%; transform: rotate(30deg)"
            src="/img/undraw_alarm-clock.svg"
            alt=""
        />
        <img
            class="position-absolute d-none d-lg-block"
            style="right: 10%; opacity: 90%; transform: rotate(-10deg)"
            src="/img/undraw_pointer.svg"
            alt=""
        />
        <img
            class="position-absolute d-none d-lg-block"
            style="right: 35%; top: 40%; opacity: 50%"
            src="/img/undraw_code.svg"
            alt=""
        />
        <img
            class="position-absolute"
            style="left: 10%; bottom: 30%; opacity: 50%; transform: rotate(30deg)"
            src="/img/undraw_star.svg"
            alt=""
        />
        <img
            class="position-absolute d-none d-lg-block"
            style="width: 100px; bottom: 10%; left: 30%; transform: scaleX(-1)"
            src="/img/undraw_fun-arrow.svg"
            alt=""
        />
        <div class="col-12 col-lg">
          <div>
            <h1 class="display-4" style="font-weight: 700; margin-bottom: 1rem">
              ¡Empecemos a aprender,
              <span class="text-primary text-capitalize">{{
                  `${persona.nombres} ${persona.apellidos}`
                }}</span
              >!
            </h1>
            <p class="h3">
              Destaca entre la multitud con las habilidades más actuales y únete
              a nuestra comunidad de aprendices apasionados. Juntos, podemos
              llegar lejos y lograr grandes cosas.
            </p>
          </div>
        </div>
        <div class="col mt-5 mt-lg-0">
          <img
              src="/img/undraw_online_learning_re_qw08.svg"
              alt="learning_img"
          />
        </div>
      </div>

      <!-- curso recomendado -->
      <div v-if="cursoRandom !== null">
        <div class="mb-3">
          <h1 class="mb-3">Qué aprender ahora</h1>
          <h3 class="myBorder">Nuestras principales sugerencias para ti</h3>
        </div>
        <b-card
            bg-variant="dark"
            class="border p-2 pointer custom-card"
            @click="
            $router.push(`/helex/Ayuda/Academia/InfoCurso/${cursoRandom?.id}`)
          "
        >
          <div class="d-flex row">
            <div class="col-12 col-lg-3 d-flex align-items-center">
              <img class="img-fluid w-100 rounded" :src="cursoRandom.previewImagenUrl ?? '/img/image_cursos_alt.png'"
                   :alt="cursoRandom.titulo"/>
            </div>
            <div class="col-12 col-lg mt-3 mt-lg-0">
              <h2 class="font-weight-bold text-capitalize">
                {{ cursoRandom.titulo }}
              </h2>
              <p
                  class="h4 text-white-50 custom-text-truncate-p"
                  style="line-height: 1.5"
              >
                {{ cursoRandom.descripcion }}
              </p>

              <div class="d-flex flex-column align-items-start flex-wrap">
                <div class="d-flex align-items-center">
                  <span
                      class="custom-punto d-flex align-items-center font-weight-bold text-success my-2"
                  >
                    {{ cursoRandom.categoriaCurso }}
                  </span>
                  <span
                      class="custom-punto d-flex align-items-center text-capitalize"
                  >Por {{ cursoRandom.responsable }}</span
                  >
                  <vs-tooltip>
                    <i :class="`fas fa-${ !cursoRandom.privacidad ? 'earth-americas' : 'lock' }`"></i>
                    <template #tooltip>{{ !cursoRandom.privacidad ? "Curso público" : "Curso privado" }}
                    </template>
                  </vs-tooltip>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <span class="custom-punto d-flex align-items-center">
                    <i class="fas fa-circle-check mr-1"></i> Última
                    actualización:
                    <span class="text-primary ml-1">
                      {{ cursoRandom.ultimaActualizacion | formatLastUpdateDate }}</span
                    >
                  </span>
                  <i class="fas fa-clock mr-1"></i>
                  <span
                      class="text-white-50 custom-punto d-flex align-items-center"
                  >{{ cursoRandom.duracion }}h</span>
                  <i class="fas fa-play-circle mr-1"></i>
                  <span class="text-white-50"
                  >{{ cursoRandom.clases }} {{ cursoRandom.clases > 1 ? "clases" : "clase" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <!-- cursos nuevos -->
      <div v-if="cursosRecientes.length > 0" class="my-5">
        <h3 class="myBorder">
          Cursos agregados recientemente
          <b-badge variant="info">Nuevos</b-badge>
        </h3>
        <vs-card-group>
          <vs-card class="custom-card-academia" v-for="curso in cursosRecientes" :key="curso.id"
                   :id="`popover-${curso.id}`">
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">{{ curso.titulo }}</h3>
              </div>
            </template>
            <template #img>
              <img style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                   :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`" :alt="curso.titulo"/>
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small class="custom-punto d-flex align-items-center text-capitalize">
                  Por {{ curso.responsable }}
                </small>
                <vs-tooltip>
                  <i :class="`fas fa-${ !curso.privacidad ? 'earth-americas' : 'lock' }`"></i>
                  <template #tooltip>{{ !curso.privacidad ? "Curso público" : "Curso privado" }}
                  </template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">{{ curso.ultimaActualizacion | formatLastUpdateDate }}</span>
              </small>
            </template>
            <template #interactions>
              <vs-button icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                >{{ curso.duracion }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover v-for="curso in cursosRecientes" :target="`popover-${curso.id}-recientes`" triggers="hover focus"
                   :key="curso.id">
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i class="fas fa-clock mr-1 text-warning" style="font-size: 14px"></i>
              <b style="font-size: 14px">{{ curso.duracion }}h</b>
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
              >{{ curso.clases }}{{ curso.clases > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p class="custom-text-truncate-p text-white-50" style="font-size: 13px">
            {{ curso.descripcion }}
          </p>
          <vs-button block :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`">
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>

      <!-- curso publicos -->
      <div v-if="cursosPublicos.length > 0" class="my-5">
        <h3 class="myBorder">Cursos públicos</h3>
        <vs-card-group>
          <vs-card class="custom-card-academia" v-for="curso in cursosPublicos" :key="curso.id"
                   :id="`popover-${curso.id}-public`">
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </div>
            </template>
            <template #img>
              <img style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                   :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`" :alt="curso.titulo"/>
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small class="custom-punto d-flex align-items-center text-capitalize">
                  Por {{ curso.responsable }}
                </small>
                <vs-tooltip>
                  <i :class="`fas fa-${ !curso.privacidad ? 'earth-americas' : 'lock' }`"
                  ></i>
                  <template #tooltip>{{ !curso.privacidad ? "Curso público" : "Curso privado" }}
                  </template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">{{ curso.ultimaActualizacion | formatLastUpdateDate }}</span>
              </small>
            </template>
            <template #interactions>
              <vs-button icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                >{{ curso.duracion }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover
            v-for="curso in cursosPublicos"
            :target="`popover-${curso.id}-public`"
            triggers="hover focus"
            :key="curso.id"
        >
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i class="fas fa-clock mr-1 text-warning" style="font-size: 14px"></i>
              <b style="font-size: 14px">{{ curso.duracion }}h</b>
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
              >{{ curso.clases }}{{ curso.clases > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p class="custom-text-truncate-p text-white-50" style="font-size: 13px">
            {{ curso.descripcion }}
          </p>
          <vs-button block :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`">
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>

      <!-- curso privados -->
      <div v-if="cursosPrivados.length > 0" class="my-5">
        <h3 class="myBorder">Cursos privados</h3>
        <vs-card-group>
          <vs-card
              class="custom-card-academia"
              v-for="curso in cursosPrivados"
              :key="curso.id"
              :id="`popover-${curso.id}-private`"
          >
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </div>
            </template>
            <template #img>
              <img style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                   :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`" :alt="curso.titulo"/>
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small class="custom-punto d-flex align-items-center text-capitalize">
                  Por {{ curso.responsable }}
                </small>
                <vs-tooltip>
                  <i :class="`fas fa-${ !curso.privacidad ? 'earth-americas' : 'lock' }`"
                  ></i>
                  <template #tooltip>{{ !curso.privacidad ? "Curso público" : "Curso privado" }}
                  </template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">{{ curso.ultimaActualizacion | formatLastUpdateDate }}</span>
              </small>
            </template>
            <template #interactions>
              <vs-button icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                >{{ curso.duracion }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover
            v-for="curso in cursosPrivados"
            :target="`popover-${curso.id}-private`"
            triggers="hover focus"
            :key="curso.id"
        >
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i class="fas fa-clock mr-1 text-warning" style="font-size: 14px"></i>
              <b style="font-size: 14px">{{ curso.duracion }}h</b>
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
              >{{ curso.clases }}{{ curso.clases > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p class="custom-text-truncate-p text-white-50" style="font-size: 13px">
            {{ curso.descripcion }}
          </p>
          <vs-button block :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`">
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>

      <!-- temas recomendados cursos -->
      <div v-if="listaCursosPorCategoria.length > 0">
        <h3 class="mt-4 mb-3 myBorder">Temas recomendados para ti</h3>
        <b-tabs
            content-class="mt-3"
            class="custom-tabs"
            pills
            v-model="tabIndex"
        >
          <b-tab
              v-for="(categoria, index) in listaCursosPorCategoria"
              :title="categoria.nombre"
              :key="categoria.nombre"
              :active="index === 0"
              :title-link-class="linkClass(index)"
          >
            <vs-card-group>
              <vs-card
                  class="custom-card-academia"
                  v-for="curso in categoria.cursos"
                  :key="curso.id"
                  :id="`popover-${curso.id}-${categoria.nombre}`"
              >
                <template #title>
                  <div class="custom-text-truncate-title">
                    <h3 class="text-capitalize">
                      {{ curso.titulo }}
                    </h3>
                  </div>
                </template>
                <template #img>
                  <img
                      style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                      :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`"
                      :alt="curso.titulo"
                  />
                </template>
                <template #text>
                  <div class="d-flex align-items-center text-white-50">
                    <small
                        class="custom-punto d-flex align-items-center text-capitalize"
                    >Por {{ curso.responsable.nombres }}
                      {{ curso.responsable.apellidos }}</small
                    >
                    <vs-tooltip>
                      <i
                          :class="`fas fa-${
                          curso.privacidad === 1 ? 'earth-americas' : 'lock'
                        }`"
                      ></i>
                      <template #tooltip>{{
                          curso.privacidad === 1
                              ? "Curso público"
                              : "Curso privado"
                        }}
                      </template>
                    </vs-tooltip>
                  </div>
                  <small class="d-flex align-items-center text-white-50 mt-2">
                    <i class="fas fa-circle-check mr-1"></i> Última
                    actualización:
                    <span class="text-primary ml-1">
                      {{
                        curso.ultimaActualizacion | formatLastUpdateDate
                      }}</span
                    >
                  </small>
                  <!-- <div class="d-flex align-items-center">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="3.8"
                ></b-form-rating>
                <small class="text-muted">(30)</small>
              </div> -->
                </template>
                <template #interactions>
                  <vs-button icon>
                    <i class="far fa-clock mr-2"></i>
                    <span class="span text-white"
                    >{{
                        curso.clases.reduce(
                            (totalHoras, clase) =>
                                totalHoras + parseInt(clase.duracion),
                            0
                        )
                      }}h</span
                    >
                  </vs-button>
                  <vs-button class="btn-chat" dark icon>
                    <i class="far fa-circle-play mr-2"></i>
                    <span class="span">{{ curso.clases.length }}</span>
                  </vs-button>
                </template>
              </vs-card>
            </vs-card-group>
            <b-popover
                v-for="curso in categoria.cursos"
                :target="`popover-${curso.id}-${categoria.nombre}`"
                triggers="hover focus"
                :key="curso.id"
            >
              <template #title>
                <h3 class="custom-text-truncate-title text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </template>
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex align-items-center">
                  <i
                      class="fas fa-clock mr-1 text-warning"
                      style="font-size: 14px"
                  ></i>
                  <b style="font-size: 14px"
                  >{{
                      curso.clases.reduce(
                          (totalHoras, clase) =>
                              totalHoras + parseInt(clase.duracion),
                          0
                      )
                    }}h</b
                  >
                </div>

                <div>
                  <i
                      class="fas fa-play-circle mr-1"
                      style="font-size: 14px"
                  ></i>
                  <b class="text-primary" style="font-size: 14px"
                  >{{
                      curso.clases.length
                    }}{{ curso.clases.length > 1 ? " clases" : " clase" }}
                  </b>
                </div>
              </div>
              <p
                  class="custom-text-truncate-p text-white-50"
                  style="font-size: 13px"
              >
                {{ curso.descripcion }}
              </p>
              <vs-button block :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`">
                <i class="fas fa-video mr-2"></i> Ir a curso
              </vs-button>
            </b-popover>
          </b-tab>
        </b-tabs>
      </div>

      <!-- no hay cursos -->
      <div v-if="listaCursosAcademia.length === 0" class="text-center">
        <p class="font-weight-bold font-italic h3">No hay cursos que mostrar</p>
      </div>
    </div>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";

export default {
  name: "Cursos",
  data() {
    return {
      persona: {},
      tabIndex: 0,
      EmpresaId: null,
      cursoRandom: null,
      cursosRecientes: [],
      cursosPublicos: [],
      cursosPrivados: [],
      listaCursosAcademia: [],
      listaCursosPorCategoria: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.EmpresaId = this.persona.empresa.id;
    await this.getListCursosAcademia();
    this.$isLoading(false);
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-danger", "text-light"];
      } else {
        return ["text-light"];
      }
    },
    async getListCursosAcademia() {
      try {
        const res = await this.$store.dispatch(
            "hl_get", {path: `CursosAcademia/ObtenerClases/${this.EmpresaId}`}
        )
        if (res.respuesta) {
          this.listaCursosAcademia = res.data;

          this.cursoRandom = res.data.random[0];
          this.cursosPublicos = res.data.publicos;
          this.cursosPrivados = res.data.privados;
          this.cursosRecientes = res.data.recientes;
        }
      } catch (error) {
        console.log("error capturado: ", error);
      }
    },
  },
  filters: {
    formatLastUpdateDate(fechaOriginal) {
      return moment_timezone
          .tz(fechaOriginal, "America/Bogota")
          .format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>

.custom-card {
  opacity: 1;
  transition: opacity 1s ease 0s;
  /* background-color: #0f213f; */
}

.custom-card:hover {
  opacity: 0.6;
}

.custom-text-truncate-p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-text-truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.myBorder {
  border-left: 2px solid #f0002b;
  padding-left: 0.5rem;
}
</style>
